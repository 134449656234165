import { mapState } from 'vuex'

export const withClientId = {
  computed: {
    ...mapState({
      currentClientId: state => state.user?.currentClient?.id,
    }),
  },
}

export const withStartProps = {
  props: {
    start: String,
  },
}

export const emitLoadingStateChange = {
  watch: {
    isLoading(newValue) {
      this.$emit('loadStateChange', newValue)
    },
  },
}

const withIsLoading = {
  data() {
    return {
      isLoading: false,
    }
  },
}

export const fetchDataWhenComponentIsMounted = {
  mixins: [withIsLoading],
  async mounted() {
    this.isLoading = true
    await this.fetchData()
    this.isLoading = false
  },
  methods: {
    async fetchData() {
      throw new Error(`[${this.name}].fetchData method must be implemented`)
    },
  },
}

export const fetchDataWhenStartChange = {
  mixins: [withIsLoading],
  watch: {
    async start() {
      this.isLoading = true
      await this.fetchData()
      this.isLoading = false
    },
  },
  methods: {
    async fetchData() {
      throw new Error(`[${this.name}].fetchData method must be implemented`)
    },
  },
}
