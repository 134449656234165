<template>
  <div class="local-audience">
    <lpe-performance-local-audience-row
      :label="$t('kpis.lpePerformance.sections.localAudience.lastMonth')"
      :start="startOfMonth"
      @loadStateChange="setLocalAudienceLastMonthIsLoading"
    />
    <lpe-performance-local-audience-row
      :label="$t('kpis.lpePerformance.sections.localAudience.sinceBeginningOfTheYear')"
      :start="startOfYear"
      @loadStateChange="setLocalAudienceBeginningIsLoading"
    />
  </div>
</template>

<script>
import LpePerformanceLocalAudienceRow from './LocalAudienceRow'
import { startOfMonth, startOfYear } from '@/services/kpis.service'
import { emitLoadingStateChange } from './mixins'

export default {
  name: 'LocalAudience',
  components: { LpePerformanceLocalAudienceRow },
  mixins: [emitLoadingStateChange],
  watch: {
    localAudienceLastMonthIsLoading(v) {
      this.isLoading = v || this.localAudienceBeginningIsLoading
    },
    localAudienceBeginningIsLoading(v) {
      this.isLoading = v || this.localAudienceLastMonthIsLoading
    },
  },
  methods: {
    setLocalAudienceBeginningIsLoading(v) {
      this.localAudienceBeginningIsLoading = v
    },
    setLocalAudienceLastMonthIsLoading(v) {
      this.localAudienceLastMonthIsLoading = v
    },
  },
  data() {
    return {
      startOfMonth: startOfMonth(),
      startOfYear: startOfYear(),
      isLoading: false,
      localAudienceLastMonthIsLoading: false,
      localAudienceBeginningIsLoading: false,
    }
  },
}
</script>
