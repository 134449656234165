<template>
  <div class="lpe-performance">
    <screen-header :title="$t('kpis.lpePerformance.title')" :breadcrumbs="breadcrumbs" :is-loading="false" />
    <screen-container direction="column">
      <screen-grid>
        <screen-card ratio="1">
          <template v-slot:body>
            <ui-title :title="formatedDate" />
          </template>
        </screen-card>

        <screen-card
          ratio="1-2"
          align="center"
          :subTitle="$t('kpis.lpePerformance.sections.averagePosition.label')"
          :title="$t('kpis.lpePerformance.sections.averagePosition.title')"
          :isLoading="averagePositionIsLoading"
        >
          <template v-slot:body>
            <div class="lpe-performance__average-position">
              <lpe-performance-average-position @loadStateChange="setAveragePositionIsLoading" />
            </div>
          </template>
        </screen-card>

        <screen-card
          align="center"
          ratio="1-2"
          :title="$t('kpis.lpePerformance.sections.localAudience.title')"
          logo="logo-megaphone.svg"
          :isLoading="localAudienceIsLoading"
        >
          <template v-slot:body>
            <div class="lpe-performance__local-audience">
              <lpe-performance-local-audience @loadStateChange="setLocalAudienceIsLoading" />
            </div>
          </template>
        </screen-card>

        <screen-card
          :noPadding="true"
          align="center"
          ratio="1-2"
          :title="$t('kpis.lpePerformance.sections.topMonthPrints.title')"
          :subTitle="$t('kpis.lpePerformance.sections.topMonthPrints.label')"
          :isLoading="topMonthPrintsIsLoading"
        >
          <template v-slot:body>
            <lpe-performance-top-prints @loadStateChange="setTopMonthPrintsIsLoading" :start="beginningOfTheMonth" />
          </template>
        </screen-card>

        <screen-card
          :noPadding="true"
          ratio="1-2"
          align="center"
          :title="$t('kpis.lpePerformance.sections.topPrintsSinceBeginning.title')"
          :subTitle="$t('kpis.lpePerformance.sections.topPrintsSinceBeginning.label')"
          :isLoading="topPrintsSinceBeginningIsLoading"
        >
          <template v-slot:body>
            <lpe-performance-top-prints @loadStateChange="setTopPrintsSinceBeginningIsLoading" />
          </template>
        </screen-card>

        <screen-card
          :noPadding="true"
          ratio="1-2"
          align="center"
          :title="$t('kpis.lpePerformance.sections.topMonthKeywords.title')"
          :subTitle="$t('kpis.lpePerformance.sections.topMonthKeywords.label')"
          :isLoading="topMonthKeywordsIsLoading"
        >
          <template v-slot:body>
            <lpe-performance-top-keywords
              @loadStateChange="setTopMonthKeywordsIsLoading"
              :start="beginningOfTheMonth"
            />
          </template>
        </screen-card>

        <screen-card
          :noPadding="true"
          ratio="1-2"
          align="center"
          :title="$t('kpis.lpePerformance.sections.topKeywordsSinceBeginning.title')"
          :subTitle="$t('kpis.lpePerformance.sections.topKeywordsSinceBeginning.label')"
          :isLoading="topKeywordsSinceBeginningIsLoading"
        >
          <template v-slot:body>
            <lpe-performance-top-keywords @loadStateChange="setTopKeywordsSinceBeginningIsLoading" />
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import LpePerformanceAveragePosition from './components/AveragePosition'
import LpePerformanceLocalAudience from './components/LocalAudience'
import LpePerformanceTopPrints from './components/TopPrints'
import LpePerformanceTopKeywords from './components/TopKeywords'
import UiTitle from '@/components/UI/Title.vue'
import { startOfMonth } from '@/services/kpis.service'
import * as dateUtils from '@/utils/date.util'

export default {
  components: {
    ScreenContainer,
    ScreenCard,
    ScreenGrid,
    ScreenHeader,
    UiTitle,
    LpePerformanceAveragePosition,
    LpePerformanceLocalAudience,
    LpePerformanceTopPrints,
    LpePerformanceTopKeywords,
  },
  name: 'LPEPerformance',
  data() {
    return {
      beginningOfTheMonth: startOfMonth(),
      topMonthKeywordsIsLoading: false,
      topPrintsSinceBeginningIsLoading: false,
      topMonthPrintsIsLoading: false,
      localAudienceIsLoading: false,
      averagePositionIsLoading: false,
      topKeywordsSinceBeginningIsLoading: false,
    }
  },
  methods: {
    setAveragePositionIsLoading(state) {
      this.averagePositionIsLoading = state
    },
    setLocalAudienceIsLoading(state) {
      this.localAudienceIsLoading = state
    },
    setTopMonthKeywordsIsLoading(state) {
      this.topMonthKeywordsIsLoading = state
    },
    setTopPrintsSinceBeginningIsLoading(state) {
      this.topPrintsSinceBeginningIsLoading = state
    },
    setTopMonthPrintsIsLoading(state) {
      this.localAudienceIsLoading = state
    },
    setTopKeywordsSinceBeginningIsLoading(state) {
      this.topKeywordsSinceBeginningIsLoading = state
    },
  },
  computed: {
    formatedDate() {
      return dateUtils.formatedDate(this.date, 'LL', this.$i18n.locale)
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('kpis.list.breadcrumb'),
          route: {
            name: 'Kpis',
          },
        },
        {
          label: this.$t('kpis.lpePerformance.breadcrumb'),
          route: {
            name: 'LPEPerformance',
          },
        },
      ]
    },
  },
}
</script>
