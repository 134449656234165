<template>
  <div v-if="data" class="average-position">
    <div class="average-position__source">
      <img :src="require(`@/assets/img/svg/${data.source}.svg`)" class="average-position__source__svg" />
    </div>

    <div class="average-position__middle">
      <ui-title class="average-position__middle__value" :title="'' + data.value" />
      <div class="average-position__middle__new-count">
        {{ data.newCount }}
      </div>
    </div>

    <lpe-performance-trend-icon class="average-position__trend" :value="data.trend" />
  </div>
</template>

<style lang="scss" scoped>
.average-position {
  display: flex;
  align-items: center;
  justify-content: space-around;

  &__middle {
    text-align: center;
    &__value {
      font-size: 3rem;
    }
  }

  &__source {
    &__svg {
      @include button-icon;
    }
  }
}
</style>

<script>
import UiTitle from '@/components/UI/Title'
import LpePerformanceTrendIcon from './TrendIcon'
import { getAveragePosition } from '@/services/kpis.service'
import {
  withClientId,
  withStartProps,
  fetchDataWhenStartChange,
  fetchDataWhenComponentIsMounted,
  emitLoadingStateChange,
} from './mixins'

export default {
  name: 'AveragePosition',
  components: { UiTitle, LpePerformanceTrendIcon },
  mixins: [
    withClientId,
    withStartProps,
    fetchDataWhenStartChange,
    fetchDataWhenComponentIsMounted,
    emitLoadingStateChange,
  ],
  data() {
    return {
      data: null,
    }
  },
  methods: {
    async fetchData() {
      const { value, source, trend, newCount } = this.start
        ? await getAveragePosition(this.currentClientId, this.start)
        : await getAveragePosition(this.currentClientId)
      this.data = { value, source, trend, newCount }
    },
  },
}
</script>
