<template>
  <div v-if="data.length > 0" class="lpe-performance__top-month-keyword">
    <div class="lpe-performance__top-month-keyword__list" v-if="isMobile || isTablet">
      <ui-data-list :items="data" :is-loading="isLoading">
        <template v-slot:skeleton>
          <div class="lpe-performance__top-month-keyword__print__list__content">
            <skeleton-line height="18px" width="80%" />
            <skeleton-line height="15px" width="50%" margin-top="3px" />
          </div>
        </template>
        <template v-slot:item="slotProps">
          <div class="lpe-performance__top-month-keyword__print__list__data">
            <div class="lpe-performance__top-month-keyword__print__list__data__row">
              <div class="lpe-performance__top-month-keyword__print__list__data__row__name">
                <span class="lpe-performance__top-month-keyword__print__list__data__row__name__span"> 4.5 </span>
                {{ slotProps.item.name }}
              </div>
              <div class="lpe-performance__top-month-keyword__print__list__data__row__value">
                {{ slotProps.item.value }}
                <ui-logo logo="logo-bubble.svg" width="1.3rem"></ui-logo>
              </div>
            </div>
            <div class="lpe-performance__top-month-keyword__print__list__data__row">
              <div class="lpe-performance__top-month-keyword__print__list__data__row__content">
                <div class="lpe-performance__top-month-keyword__print__list__data__row__content__label">
                  {{ $t('kpis.lpePerformance.sections.topMonthPrints.GraphicBar.label') }}
                </div>
                <div class="lpe-performance__top-month-keyword__print__list__data__row__content__graphic-bar"></div>
              </div>
              <lpe-performance-trend-icon
                v-if="slotProps.item.trend"
                class="lpe-performance__top-month-keyword__print__list__row__trend"
                :value="slotProps.item.trend"
              />
            </div>
          </div>
        </template>
      </ui-data-list>
    </div>
    <div class="lpe-performance__top-month-keyword__list" v-else>
      <ui-data-table
        :totalRows="data.length"
        :isLoading="isLoading"
        :columns="[
          { label: $t('kpis.lpePerformance.sections.topMonthPrints.columns.name'), field: 'name' },
          { label: $t('kpis.lpePerformance.sections.topMonthPrints.columns.note'), field: 'note' },
          { label: $t('kpis.lpePerformance.sections.topMonthPrints.columns.value'), field: 'value' },
          { label: $t('kpis.lpePerformance.sections.topMonthPrints.columns.graphicBar'), field: 'graphicBar' },
          { label: $t('kpis.lpePerformance.sections.topMonthPrints.columns.trend'), field: 'trend' },
        ]"
        :rows="data"
        :isSelectable="false"
        :with-pagination="false"
      >
        <template slot="data-table-row" slot-scope="{ formattedRow, column }">
          <template v-if="column.field == 'name'">
            <div class="lpe-performance__top-month-keyword__print__table__name">
              {{ formattedRow[column.field] }}
            </div>
          </template>
          <template v-if="column.field == 'note'">
            <div class="lpe-performance__top-month-keyword__print__table__note">4.5</div>
          </template>
          <template v-if="column.field == 'value'">
            <div class="lpe-performance__top-month-keyword__print__table__value">
              {{ formattedRow[column.field] }}
            </div>
          </template>
          <template v-if="column.field == 'graphicBar'">
            <div class="lpe-performance__top-month-keyword__print__table__graphic-bar"></div>
          </template>
          <template v-if="column.field == 'trend' && formattedRow[column.field].length > 0">
            <lpe-performance-trend-icon :disabledBackground="true" :value="formattedRow[column.field]" />
          </template>
        </template>
      </ui-data-table>
    </div>
  </div>
</template>

<script>
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiLogo from '@/components/UI/Logo.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import LpePerformanceTrendIcon from './TrendIcon'
import { getTopKeywordsByCity } from '@/services/kpis.service'
import { onScroll } from '@/mixins/scroll.mixin'
import {
  withClientId,
  withStartProps,
  fetchDataWhenStartChange,
  fetchDataWhenComponentIsMounted,
  emitLoadingStateChange,
} from './mixins'

export default {
  name: 'LPEPerformanceTopMonthKeyword',
  components: {
    UiDataTable,
    UiDataList,
    UiLogo,
    SkeletonLine,
    LpePerformanceTrendIcon,
  },
  mixins: [
    onScroll,
    withClientId,
    withStartProps,
    fetchDataWhenStartChange,
    fetchDataWhenComponentIsMounted,
    emitLoadingStateChange,
  ],
  data() {
    return {
      data: [],
    }
  },
  methods: {
    async fetchData() {
      const { data } = this.start
        ? await getTopKeywordsByCity(this.currentClientId, this.start)
        : await getTopKeywordsByCity(this.currentClientId)
      this.data = data
    },
  },
}
</script>

<style lang="scss" scoped>
.lpe-performance {
  &__top-month-keyword {
    &__print {
      &__list {
        box-shadow: 0 7px 12px 0 rgb(0 0 0 / 18%);

        &__data {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &:first-child {
              margin-bottom: 4px;
            }

            &__name {
              font-size: $font-size-lg;
              font-weight: $font-weight-title;

              &__span {
                font-weight: $font-weight-max;
              }
            }

            &__value {
              color: $blue-downriver;
              font-size: $font-size-lg;
              font-weight: $font-weight-default;
            }

            &__content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 55%;

              &__label {
                color: $blue-bayoux;
                font-size: $font-size-sm;
              }

              &__graphic-bar {
                border-radius: 2px;
                background-color: $green-salem;
                width: 100%;
                height: 10px;
              }
            }
          }
        }
      }

      &__table {
        &__name {
          font-size: $font-size-md;
          font-weight: $font-weight-700;
        }

        &__note {
          font-size: $font-size-lg;
          font-weight: $font-weight-800;
        }

        &__value {
          font-size: $font-size-lg;
          font-weight: $font-weight-medium;
        }

        &__graphic-bar {
          border-radius: 2px;
          background-color: $green-salem;
          width: 100%;
          height: 10px;
        }
      }
    }
  }
}
</style>
