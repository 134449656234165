<template>
  <div v-if="isLoaded" class="local-audience-row">
    <div class="local-audience-row__label">
      {{ label }}
    </div>
    <div class="local-audience-row__indicators">
      <div class="local-audience-row__indicators__prints">
        <div class="local-audience-row__indicators__prints__label">
          {{ $t('kpis.lpePerformance.sections.localAudience.prints') }}
        </div>
        <div class="local-audience-row__indicators__prints__value">
          {{ data.prints.value }}
        </div>
        <lpe-performance-trend-icon
          v-if="data.prints.trend"
          class="local-audience-row__indicators__prints__status"
          :value="data.prints.trend"
        />
      </div>
      <div class="local-audience-row__indicators__visits">
        <div class="local-audience-row__indicators__visits__label">
          {{ $t('kpis.lpePerformance.sections.localAudience.visits') }}
        </div>
        <div class="local-audience-row__indicators__visits__value">
          {{ data.visits.value }}
        </div>
        <lpe-performance-trend-icon
          v-if="data.visits.trend"
          class="local-audience-row__indicators__visits__status"
          :value="data.visits.trend"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.local-audience-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__label {
    margin-bottom: 1rem;
    border-radius: $radius-button;
    background-color: $grey-athens;
    padding: $button-padding-sm;
    text-align: center;
    font-weight: $font-weight-title;
  }

  &__indicators {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    width: 100%;

    &__prints,
    &__visits {
      margin: 0 2px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
      padding: $gutter-mobile;
      width: 48%;
      text-align: center;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
      }

      &__label {
        color: $blue-downriver;
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
      }

      &__value {
        margin: 1.5rem 0;
        color: $blue-downriver;
        font-size: $font-size-xxxl;
        font-weight: $font-weight-max;
      }
    }
  }
}
</style>

<script>
import LpePerformanceTrendIcon from './TrendIcon'
import { getLocalPageAudience } from '@/services/kpis.service'
import {
  withClientId,
  withStartProps,
  fetchDataWhenStartChange,
  fetchDataWhenComponentIsMounted,
  emitLoadingStateChange,
} from './mixins'

export default {
  name: 'LocalAudienceRow',
  components: { LpePerformanceTrendIcon },
  mixins: [
    withClientId,
    withStartProps,
    fetchDataWhenStartChange,
    fetchDataWhenComponentIsMounted,
    emitLoadingStateChange,
  ],
  props: {
    label: String,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    isLoaded() {
      return this.data?.prints && this.data?.visits
    },
  },
  methods: {
    async fetchData() {
      const { data } = this.start
        ? await getLocalPageAudience(this.currentClientId, this.start)
        : await getLocalPageAudience(this.currentClientId)
      this.data = data
    },
  },
}
</script>
