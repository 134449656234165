<template>
  <div :class="[trendClassBackground, disabledBackgroundClass]" class="trend-icon">
    <div class="trend-icon__label">
      {{ $t(trendText) }}
    </div>
    <div :class="['trend-icon__icon backoffice-icons', trendClassIcon]">
      {{ trendIcon }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.trend-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 6px 0.6rem;
  font-weight: $font-weight-default;

  &--up {
    background-color: #cde9da;
    color: $trend-icon-up-color;
  }

  &--down {
    background-color: #f5d4d1;
    color: $trend-icon-down-color;
  }

  &--equal {
    background-color: #d7e5ff;
    color: $trend-icon-equal-color;
  }

  &--disabled-background {
    background-color: initial;
  }

  &__icon {
    padding-left: 4px;
    font-size: $font-size-default;
    &--up {
      color: $trend-icon-up-color;
    }
    &--down {
      color: $trend-icon-down-color;
    }
    &--equal {
      color: $trend-icon-equal-color;
    }
  }
}
</style>

<script>
export default {
  name: 'TrendIcon',
  computed: {
    trendClassIcon() {
      switch (this.value) {
        case 'up':
          return 'trend-icon__icon--up'
        case 'down':
          return 'trend-icon__icon--down'
        case 'equal':
          return 'trend-icon__icon--equal'
        default:
          return ''
      }
    },
    trendClassBackground() {
      switch (this.value) {
        case 'up':
          return 'trend-icon--up'
        case 'down':
          return 'trend-icon--down'
        case 'equal':
          return 'trend-icon--equal'
        default:
          return ''
      }
    },
    trendIcon() {
      switch (this.value) {
        case 'up':
          return 'arrow-up-right'
        case 'down':
          return 'arrow-down-right'
        case 'equal':
          return 'equal'
        default:
          return ''
      }
    },
    trendText() {
      switch (this.value) {
        case 'up':
          return 'kpis.lpePerformance.trends.up'
        case 'down':
          return 'kpis.lpePerformance.trends.down'
        case 'equal':
          return 'kpis.lpePerformance.trends.equal'
        default:
          return ''
      }
    },
    disabledBackgroundClass() {
      return this.disabledBackground ? 'trend-icon--disabled-background' : false
    },
  },
  props: {
    value: {
      type: String,
      validator: v => ['up', 'down', 'equal'].includes(v),
    },
    disabledBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
